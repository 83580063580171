// This is the main entrypoint for the accounts layout
// Things that need to be set up globally for all accounts related pages go here.
// Run this script by adding <%= javascript_pack_tag 'accounts' %> to the head of your layout file,
// like app/views/layouts/accounts.html.erb.

document.addEventListener('DOMContentLoaded', () => {
  // If the user is getting to the login page by logging out from Zoom, add a query param to the URL so we can tell
  const currentUrl = window.location.href;

  if (document.referrer.includes('zoom_login=true') && !currentUrl.includes('zoom_login=true')) {
    window.location.href = `${currentUrl}?zoom_login=true`;
  }
});
